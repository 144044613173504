import React from "react";
import { GamePageData } from "./RecipeRescuePageData";

export const slotUniversePageData: GamePageData = {
    metaPageTitle: "Slot Universe Vegas Slot Machine Game - iDream Interactive",
    metaKeywords: "slot universe, slot universe slot machine, vegas slot machine",
    metaDescription: "Fresh, fun and exciting experience that brings Vegas to you! Learn more about our most successful slot machine game which was available on Facebook.",
    pageTitle: "Slot Universe",
    descriptionTitle: "Fresh, fun and exciting experience that brings Vegas to you!",
    description: <>
        <p>Filled with hundreds of unique slot machines, awesome powerups, and HUGE payouts, Slot Universe will keep you coming back for more.</p>
        <p>This was our first slot machine and our most successful.  Released in 2010, it was one of the first slot machine apps to thrive on the Facebook platform.  The success of this game gave us the confidence to continue making amazing titles.</p>
    </>,
    links: [
    ]
}
