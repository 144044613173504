import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout/Layout";
import { GameDescription } from "../../components/sections/games/GameDescription";
import { Container } from "../../components/layout/Container";
import { Navigation } from "../../components/navigation/Navigation";
import { HeaderSize } from "../../components/layout/ParallaxHeader";
import { ImageHeader } from "../../components/layout/ImageHeader";
import Img, { FluidObject } from "gatsby-image";
import { GalleryOrientation, GameGallery } from "../../components/sections/games/GameGallery";
import { ContainerColour } from "../../components/layout/DiagonalContainer";
import { slotUniversePageData } from "../../content/pageData/games/SlotUniversePageData";
import { ButtonColour, ButtonSize, Button } from "../../components/layout/Button";

type PageData = {
    data: {
        site: {
            siteMetadata: {
                title: string;
                description: string;
            }
        }
        headerMobile: {
            childImageSharp: any;
        }
        headerXL: {
            childImageSharp: any;
        }
        header: {
            childImageSharp: any;
        }
        logo: {
            childImageSharp: any;
        }
        description: {
            childImageSharp: any;
        }
        one: {
            childImageSharp: any;
        }
        two: {
            childImageSharp: any;
        }
        three: {
            childImageSharp: any;
        }
        lady: {
            childImageSharp: any;
        }
    }
}

export default ({ data }: PageData) => {
    const headerImageMobile = data.headerMobile.childImageSharp.fluid;
    const headerImageXL = data.headerXL.childImageSharp.fluid;
    const headerImage = data.header.childImageSharp.fluid;

    const headerSources = [
        headerImageMobile,
        {
            ...headerImageXL,
            media: `(min-width: 1100px)`,
        },
        {
            ...headerImage,
            media: `(min-width: 768px)`,
        }
    ]

    const images: FluidObject[] = [
        data.one.childImageSharp.fluid,
        data.two.childImageSharp.fluid,
        data.three.childImageSharp.fluid,
    ];

    return (
        <Layout
            pageTitle={slotUniversePageData.metaPageTitle}
            keywords={slotUniversePageData.metaKeywords}
            description={slotUniversePageData.metaDescription}
            url={"work/slot-universe"}
        >
            <Navigation />
            <ImageHeader size={HeaderSize.LARGE} imageSrc={headerSources} archived={true}>
                <div className="logo-container">
                    <div className="image-wrapper su">
                        <Img fluid={data.logo.childImageSharp.fluid} alt={`${slotUniversePageData.pageTitle} logo`} />
                    </div>
                </div>
            </ImageHeader>
            <Container className="game-description-container">
                <GameDescription
                    gameTitle={"Slot Universe"}
                    title={slotUniversePageData.descriptionTitle}
                    description={slotUniversePageData.description}
                    links={slotUniversePageData.links}
                    image={data.description.childImageSharp.fluid}
                    imageAlt={"Dancer in red dress"}
                />
            </Container>
            <GameGallery colour={ContainerColour.SU} images={images} orientation={GalleryOrientation.SQUARE}>
                <div className="su-lady">
                    <Img fluid={data.lady.childImageSharp.fluid} alt="Dancer in purple dress" />
                </div>
            </GameGallery>
            <Container className="more-work">
                <Button buttonConfig={{
                    colour: ButtonColour.BLUE,
                    size: ButtonSize.LARGE,
                    ctaLabel: "More Work",
                    ctaLink: "/work",
                    id: "blueGradient",
                    ariaLabel: "View our other work",
                }}
                />
            </Container>
        </Layout>
    );
};


export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
        headerMobile: file(relativePath: { eq: "assets/images/work/su-header.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 768, maxHeight: 550, quality: 100, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        headerXL: file(relativePath: { eq: "assets/images/work/su-header.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1581, maxHeight: 700, quality: 100, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        header: file(relativePath: { eq: "assets/images/work/su-header.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        logo: file(relativePath: { eq: "assets/images/work/su/logo.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        description: file(relativePath: { eq: "assets/images/work/su/description.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        one: file(relativePath: { eq: "assets/images/work/su/1.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 864, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        two: file(relativePath: { eq: "assets/images/work/su/2.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 864, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        three: file(relativePath: { eq: "assets/images/work/su/3.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 864, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        lady: file(relativePath: { eq: "assets/images/work/su/lady.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`